import "./index.scss";

const BASE_CLASS = "dia-Notification";

export default function Notification({
  title,
  message,
  type,
}: {
  title: string;
  message: string;
  type: "success" | "error" | "warning";
}) {
  return (
    <div className={`${BASE_CLASS} ${BASE_CLASS}--${type}`}>
      <h2 className={`${BASE_CLASS}-title`}>{title}</h2>
      <p className={`${BASE_CLASS}-message`}>{message}</p>
    </div>
  );
}
