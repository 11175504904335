import "./index.scss";

import { CustomerDetailData } from "../../../../types/Customer.model";
import { AiOutlinePhone } from "react-icons/ai";
import { useEffect, useState } from "react";
import { LoadingPage } from "../../../../components/Spinner";
import InteractionSection from "./InteractionsSection";
import NotesSection from "./NotesSection";
import EditProfilePage from "./EditProfilePage";

const BASE_CLASS = "dia-DashboardCustomerDetail";

const renderContent = ({
  selectedTab,
  customerDetailData,
  onClose,
  setCustomerDetailData,
}: {
  selectedTab: string;
  customerDetailData: CustomerDetailData;
  onClose: () => void;
  setCustomerDetailData: React.Dispatch<
    React.SetStateAction<CustomerDetailData | undefined>
  >;
}) => {
  switch (selectedTab) {
    case "Interactions":
      return <InteractionSection email={customerDetailData.email} />;
    case "Notes":
      return <NotesSection notes={customerDetailData.notes} />;
    case "Summaries":
      return <div>Summaries</div>;
    case "To Do's":
      return <div>To Do's</div>;
    case "Meetings":
      return <div>Meetings</div>;
    case "Tags":
      return <div>Tags</div>;
    case "Edit":
      return (
        <EditProfilePage
          onClose={onClose}
          customerDataProps={customerDetailData}
          setCustomerDetailDataProps={setCustomerDetailData}
        />
      );
    default:
      return <div>Interactions</div>;
  }
};

export default function CustomerDetail({
  customerId,
  onClose,
}: {
  onClose: () => void;
  customerId?: string;
}) {
  const [selectedTab, setSelectedTab] = useState("Interactions");
  const [customerDetailData, setCustomerDetailData] =
    useState<CustomerDetailData>();
  const BASE_URL =
    process.env.REACT_APP_STAGE === "development"
      ? "http://localhost:4000"
      : "https://api.daialog.app";

  useEffect(() => {
    fetch(`${BASE_URL}/customer/${customerId}/detail`).then((res) => {
      res.json().then((data) => {
        const customerData: CustomerDetailData = data.customerData;
        setCustomerDetailData(customerData);
      });
    });
  }, [customerId]);

  return customerDetailData ? (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-header`}>
        <div className={`${BASE_CLASS}-header-start`}>
          <div className={`${BASE_CLASS}-header-avatar`}>
            {customerDetailData?.profilePicture ? (
              <img
                src={customerDetailData?.profilePicture}
                alt={customerDetailData?.firstName}
                className={`${BASE_CLASS}-header-avatar-img`}
              />
            ) : (
              <p className={`${BASE_CLASS}-header-avatar-placeholder`}>
                {customerDetailData?.firstName?.charAt(0)}
              </p>
            )}
          </div>
          <div className={`${BASE_CLASS}-header-info`}>
            <div className={`${BASE_CLASS}-header-info-status`}>
              <h2 className={`${BASE_CLASS}-header-info-name`}>
                {customerDetailData?.firstName} {customerDetailData?.lastName}
              </h2>
              <p className={`${BASE_CLASS}-header-info-status-text`}>Status</p>
            </div>
            <p className={`${BASE_CLASS}-header-info-email`}>
              {customerDetailData?.email}
            </p>
            <p className={`${BASE_CLASS}-header-info-position`}>
              Frontend developer
            </p>
          </div>
          <a
            href={`tel:${customerDetailData?.phone}`}
            className={`${BASE_CLASS}-header-phone`}
          >
            <AiOutlinePhone
              size={18}
              className={`${BASE_CLASS}-header-info-phone-icon`}
            />
            {customerDetailData?.phone}
          </a>
        </div>
        <div className={`${BASE_CLASS}-header-info-social`}>
          {customerDetailData?.socialMedia?.linkedin && (
            <a href={customerDetailData?.socialMedia?.linkedin}>
              <img
                src="https://img.icons8.com/color/48/000000/linkedin.png"
                alt="linkedin"
              />
            </a>
          )}
          {customerDetailData?.socialMedia?.instagram && (
            <a href={customerDetailData?.socialMedia?.instagram}>
              <img
                src="https://img.icons8.com/color/48/000000/instagram-new.png"
                alt="instagram"
              />
            </a>
          )}
          {customerDetailData?.socialMedia?.twitter && (
            <a href={customerDetailData?.socialMedia?.twitter}>
              <img
                src="https://img.icons8.com/color/48/000000/twitter--v1.png"
                alt="twitter"
              />
            </a>
          )}
          {customerDetailData?.socialMedia?.facebook && (
            <a href={customerDetailData?.socialMedia?.facebook}>
              <img
                src="https://img.icons8.com/color/48/000000/facebook.png"
                alt="facebook"
              />
            </a>
          )}
        </div>
      </div>
      <div className={`${BASE_CLASS}-menu`}>
        <span
          className={`${BASE_CLASS}-menu-item ${
            selectedTab === "Interactions"
              ? `${BASE_CLASS}-menu-item-active`
              : ""
          }`}
          onClick={() => setSelectedTab("Interactions")}
        >
          Interactions ({customerDetailData.interactions.total || 0})
        </span>
        <span
          className={`${BASE_CLASS}-menu-item ${
            selectedTab === "Notes" ? `${BASE_CLASS}-menu-item-active` : ""
          }`}
          onClick={() => setSelectedTab("Notes")}
        >
          Notes ({customerDetailData.notes.total || 0})
        </span>
        <span
          className={`${BASE_CLASS}-menu-item ${
            selectedTab === "Summaries" ? `${BASE_CLASS}-menu-item-active` : ""
          }`}
          onClick={() => setSelectedTab("Summaries")}
        >
          Summaries ({customerDetailData.summaries.total || 0})
        </span>
        <span
          className={`${BASE_CLASS}-menu-item ${
            selectedTab === "To Do's" ? `${BASE_CLASS}-menu-item-active` : ""
          }`}
          onClick={() => setSelectedTab("To Do's")}
        >
          To Do's ({customerDetailData.todos || 0})
        </span>
        <span
          className={`${BASE_CLASS}-menu-item ${
            selectedTab === "Meetings" ? `${BASE_CLASS}-menu-item-active` : ""
          }`}
          onClick={() => setSelectedTab("Meetings")}
        >
          Meetings ({customerDetailData.meetings.total || 0})
        </span>
        <span
          className={`${BASE_CLASS}-menu-item ${
            selectedTab === "Tags" ? `${BASE_CLASS}-menu-item-active` : ""
          }`}
          onClick={() => setSelectedTab("Tags")}
        >
          Tags (0)
        </span>
        <span
          className={`${BASE_CLASS}-menu-item ${
            selectedTab === "Edit" ? `${BASE_CLASS}-menu-item-active` : ""
          }`}
          onClick={() => setSelectedTab("Edit")}
        >
          Edit Profile
        </span>
      </div>
      <div className={`${BASE_CLASS}-content`}>
        {renderContent({
          selectedTab,
          customerDetailData,
          onClose,
          setCustomerDetailData,
        })}
      </div>
    </div>
  ) : (
    <LoadingPage />
  );
}
