import { ChatResponseParsed } from "../../../types/ChatResponse.model";
import chatRepository from "../../repositories/chat/chatRepository";

export default async function sendMessage({
  message,
}: {
  message: string;
}): Promise<ChatResponseParsed> {
  try {
    return chatRepository.sendMessage({ message });
  } catch (error: any) {
    throw new Error(error);
  }
}
