import { GmailResponse } from "../../../types/Gmail.model";
import gmailApi from "../../api/gmailApi";

const gmailRepository = {
  getEmails: async ({
    userId,
    from,
  }: {
    userId: string;
    from?: string;
  }): Promise<GmailResponse[]> => {
    return gmailApi.getEmails({ userId, from });
  },
};

export default gmailRepository;
