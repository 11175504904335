import React, { createContext, useState } from "react";
import sendMessage from "../services/useCases/chat/sendMessage";

export interface Message {
  text: string;
  user: "bot" | "user";
  time?: string;
}

interface ChatContextType {
  messages: Message[];
  sendMessage: (message: string) => void;
  isLoading: boolean;
}

export const ChatContext = createContext<ChatContextType>({
  messages: [],
  sendMessage: () => {},
  isLoading: false,
});

export const ChatProvider = ({ children }: { children: React.ReactNode }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const sendMessageHandler = async (message: string) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        time: new Date().toISOString(),
        text: message,
        user: "user",
      },
    ]);
    setIsLoading(true);

    sendMessage({ message })
      .then((data) => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            time: data.createdAt,
            text: data.message,
            user: "bot",
          },
        ]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ChatContext.Provider
      value={{ messages, sendMessage: sendMessageHandler, isLoading }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = (): ChatContextType => {
  if (!ChatContext) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return React.useContext(ChatContext);
};
