import "./index.scss";

const BASE_CLASS = "dia-Input";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  initialValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Input({
  label,
  initialValue,
  onChange,
  ...props
}: InputProps) {
  return (
    <div className={BASE_CLASS}>
      {label && <label className={`${BASE_CLASS}-label`}>{label}</label>}
      <div className={`${BASE_CLASS}-input-wrapper`}>
        <input
          className={`${BASE_CLASS}-input`}
          onChange={onChange}
          {...props}
        />
      </div>
    </div>
  );
}
