import "./index.scss";

import { SignUp } from "@clerk/clerk-react";

const BASE_CLASS = "dia-SignUpPage";

export default function SignUpPage() {
  return (
    <div className={BASE_CLASS}>
      <SignUp path="/sign-up" />
    </div>
  );
}
