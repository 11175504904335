import "./index.scss";
import logo from "../../../assets/logo/logo.png";
import { SignIn } from "@clerk/clerk-react";

const BASE_CLASS = "dia-SignInPage";

export default function SignInPage() {
  return (
    <div className={BASE_CLASS}>
      <SignIn
        appearance={{
          layout: {
            logoImageUrl: logo,
            logoPlacement: "inside",
            socialButtonsVariant: "iconButton",
          },
        }}
        path="/sign-in"
      />
    </div>
  );
}
