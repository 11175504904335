import { User } from "../../../types/User.model";
import userApi from "../../api/userApi";

const userRepository = {
  getUser: async ({ userId }: { userId: string }) => {
    return userApi.getUser({ userId });
  },
  updateUser: async ({ userData }: { userData: User }) => {
    return userApi.updateUser({ userData });
  },
};

export default userRepository;
