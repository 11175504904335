import "./index.scss";

import { Customer } from "../../../../types/Customer.model";
import { AiOutlinePhone } from "react-icons/ai";
import { useState } from "react";

const BASE_CLASS = "dia-DashboardCompanyDetail";

export default function CompanyDetail({ customer }: { customer?: Customer }) {
  const [selectedTab, setSelectedTab] = useState("Interactions");

  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-header`}>
        <div className={`${BASE_CLASS}-header-start`}>
          <div className={`${BASE_CLASS}-header-avatar`}>
            {customer?.profilePicture ? (
              <img
                src={customer?.profilePicture}
                alt={customer?.firstName}
                className={`${BASE_CLASS}-header-avatar-img`}
              />
            ) : (
              <p className={`${BASE_CLASS}-header-avatar-placeholder`}>
                {customer?.firstName?.charAt(0)}
              </p>
            )}
          </div>
          <div className={`${BASE_CLASS}-header-info`}>
            <div className={`${BASE_CLASS}-header-info-status`}>
              <h2 className={`${BASE_CLASS}-header-info-name`}>
                {customer?.firstName} {customer?.lastName}
              </h2>
              <p className={`${BASE_CLASS}-header-info-status-text`}>Status</p>
            </div>
            <p className={`${BASE_CLASS}-header-info-email`}>
              {customer?.email}
            </p>
            <p className={`${BASE_CLASS}-header-info-position`}>
              Frontend developer
            </p>
          </div>
          <div className={`${BASE_CLASS}-header-phone`}>
            <AiOutlinePhone
              size={18}
              className={`${BASE_CLASS}-header-info-phone-icon`}
            />
            {customer?.phone}
          </div>
        </div>
      </div>
      <div className={`${BASE_CLASS}-menu`}>
        <span
          className={`${BASE_CLASS}-menu-item ${
            selectedTab === "Interactions"
              ? `${BASE_CLASS}-menu-item-active`
              : ""
          }`}
          onClick={() => setSelectedTab("Interactions")}
        >
          Interactions
        </span>
        <span
          className={`${BASE_CLASS}-menu-item ${
            selectedTab === "Notes" ? `${BASE_CLASS}-menu-item-active` : ""
          }`}
          onClick={() => setSelectedTab("Notes")}
        >
          Notes
        </span>
        <span
          className={`${BASE_CLASS}-menu-item ${
            selectedTab === "Summaries" ? `${BASE_CLASS}-menu-item-active` : ""
          }`}
          onClick={() => setSelectedTab("Summaries")}
        >
          Summaries
        </span>
        <span
          className={`${BASE_CLASS}-menu-item ${
            selectedTab === "To Do's" ? `${BASE_CLASS}-menu-item-active` : ""
          }`}
          onClick={() => setSelectedTab("To Do's")}
        >
          To Do's
        </span>
        <span
          className={`${BASE_CLASS}-menu-item ${
            selectedTab === "Meetings" ? `${BASE_CLASS}-menu-item-active` : ""
          }`}
          onClick={() => setSelectedTab("Meetings")}
        >
          Meetings
        </span>
        <span
          className={`${BASE_CLASS}-menu-item ${
            selectedTab === "Tags" ? `${BASE_CLASS}-menu-item-active` : ""
          }`}
          onClick={() => setSelectedTab("Tags")}
        >
          Tags
        </span>
      </div>
    </div>
  );
}
