import "./index.scss";

const BASE_CLASS = "dia-Spinner";

export function Spinner() {
  return <span className={BASE_CLASS} />;
}

export function LoadingPage() {
  return (
    <div className={`${BASE_CLASS}-container`}>
      <Spinner />
    </div>
  );
}
