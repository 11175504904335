import { useState } from "react";
import logo from "../assets/logo/logo.png";
import { User } from "../types/User.model";
import { useNavigate } from "react-router-dom";
import updateUser from "../services/useCases/user/updateUser";
import { UserEntity } from "../services/entities/UserEntity";
import { useUserContext } from "../contexts/UserContext";

// Define the stages of the conversation
enum STAGES {
  USERNAME = "USERNAME",
  EMAIL = "EMAIL",
  CONFIRMATION = "CONFIRMATION",
  CORRECTION = "CORRECTION",
}

// Type for individual message
interface Message {
  message: string;
  isUser: boolean;
  profilePicture: string;
  name: string;
}

// Type for user data collected during the chat
interface UserDataCollected {
  username: string;
  email: string;
}

// Custom hook for the chatbot logic
export function useChatbot(userData: User) {
  const navigate = useNavigate();
  const { getUserData } = useUserContext();
  // Initial state for messages
  const [messages, setMessages] = useState<Message[]>([
    {
      message:
        "Hello Pol, welcome to Daialog. Before we start and to get to know each other a little better, I'll need you to answer a few basic questions. First, what would you like your username to be?",
      isUser: false,
      profilePicture: logo,
      name: "Richard",
    },
  ]);

  // State for the input value
  const [inputValue, setInputValue] = useState<string>("");

  // State for the current stage of the conversation
  const [stage, setStage] = useState<STAGES>(STAGES.USERNAME);

  // State for storing user data collected during the chat
  const [userDataCollected, setUserDataCollected] = useState<UserDataCollected>(
    {
      username: "",
      email: "",
    }
  );

  // Function to handle sending a message
  const handleSend = async () => {
    if (inputValue.trim() === "") return;

    // Add user's message to the chat
    const newMessages: Message[] = [
      ...messages,
      {
        message: inputValue,
        isUser: true,
        profilePicture: userData?.profilePicture || "",
        name: userData?.fullName || "You",
      },
    ];
    setMessages(newMessages);

    let aiMessage = "";
    let nextStage = stage;
    // Clear the input field
    setInputValue("");

    // Simulate AI response based on current stage
    setTimeout(async () => {
      switch (stage) {
        case STAGES.USERNAME:
          aiMessage = `Nice to meet you, ${inputValue}! What is your email address?`;
          setUserDataCollected((prevData) => ({
            ...prevData,
            username: inputValue,
          }));
          nextStage = STAGES.EMAIL;
          break;

        case STAGES.EMAIL:
          if (inputValue.includes("@")) {
            aiMessage = `Great, thanks! Here's what I have:\nUsername: ${userDataCollected.username}\nEmail: ${inputValue}\nIs everything correct? (yes/no)`;
            setUserDataCollected((prevData) => ({
              ...prevData,
              email: inputValue,
            }));
            nextStage = STAGES.CONFIRMATION;
          } else {
            aiMessage =
              "Hmm, that doesn't look like a valid email. Could you try again?";
            nextStage = STAGES.EMAIL;
          }
          break;

        case STAGES.CONFIRMATION:
          if (inputValue.toLowerCase() === "yes") {
            aiMessage = "Thank you for the confirmation! We're all set up.";
            nextStage = STAGES.USERNAME; // Reset or move to another logical flow

            const userUpdated = new UserEntity({
              ...userData,
              ...userDataCollected,
              hasCreatedProfile: true,
            });

            await updateUser({ userData: userUpdated }).then(() => {
              getUserData().then(() => {
                navigate("/onboarding/complete");
              });
            });
          } else if (inputValue.toLowerCase() === "no") {
            aiMessage =
              "I'm sorry about that! Which field is incorrect, the username or the email?";
            nextStage = STAGES.CORRECTION;
          } else {
            aiMessage =
              "Please respond with 'yes' or 'no'. Is everything correct?";
            nextStage = STAGES.CONFIRMATION;
          }
          break;

        case STAGES.CORRECTION:
          if (inputValue.toLowerCase() === "username") {
            aiMessage = "Please enter the correct username:";
            nextStage = STAGES.USERNAME;
          } else if (inputValue.toLowerCase() === "email") {
            aiMessage = "Please enter the correct email:";
            nextStage = STAGES.EMAIL;
          } else {
            aiMessage =
              "I didn't catch that. Which field is incorrect, the username or the email?";
            nextStage = STAGES.CORRECTION;
          }
          break;

        default:
          aiMessage =
            "I'm not sure how to proceed. Can you tell me your username again?";
          nextStage = STAGES.USERNAME;
          break;
      }

      // Add AI's message to the chat
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          message: aiMessage,
          isUser: false,
          profilePicture: logo,
          name: "Richard",
        },
      ]);

      // Update the conversation stage
      setStage(nextStage);
    }, 1000); // Simulate delay
  };

  // Function to handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  // Function to handle key press (e.g., Enter key)
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  // Return all necessary states and functions to use in the component
  return {
    messages,
    inputValue,
    handleInputChange,
    handleSend,
    handleKeyPress,
  };
}
