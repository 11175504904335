import { GmailResponse } from "../../types/Gmail.model";
import { apiClient } from "./apiClient";

const gmailApi = {
  getEmails: async ({
    userId,
    from,
  }: {
    userId: string;
    from?: string;
  }): Promise<GmailResponse[]> => {
    return apiClient
      .post("/gmail", {
        userId,
        from,
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data as GmailResponse[];
        } else {
          throw new Error("Error fetching user data");
        }
      });
  },
};

export default gmailApi;
