import { DashboardEntity } from "../../entities/DashboardEntity";
import dashboardRepository from "../../repositories/dashboard/dashboardRepository";

export default function updateDashboard({
  dashboard,
}: {
  dashboard: Partial<DashboardEntity>;
}): Promise<DashboardEntity> {
  return dashboardRepository.updateDashboard({ dashboard });
}
