import { CustomerDetailEntity } from "../entities/CustomerDetailEntity";

export default function fromCustomerHTTPResponseToCustomerEntity(
  customerHttpResponse: any
): CustomerDetailEntity {
  return new CustomerDetailEntity({
    id: customerHttpResponse.id,
    firstName: customerHttpResponse.firstName,
    lastName: customerHttpResponse.lastName,
    email: customerHttpResponse.email,
    phone: customerHttpResponse.phone,
    company: customerHttpResponse.company,
    position: customerHttpResponse.position,
    profilePicture: customerHttpResponse.profilePicture,
    interactions: customerHttpResponse.interactions,
    notes: customerHttpResponse.notes,
    summaries: customerHttpResponse.summaries,
    meetings: customerHttpResponse.meetings,
    socialMedia: customerHttpResponse.socialMedia,
    todos: customerHttpResponse.todos,
  });
}
