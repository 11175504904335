import React, { createContext, useState, useEffect } from "react";
import { useAuth, useUser } from "@clerk/clerk-react";
import { UserRepository } from "../services/user/userRepository";
import createDashboardUseCase from "../services/useCases/dashboard/createDashboard";
import { DashboardEntity } from "../services/entities/DashboardEntity";
import getUser from "../services/useCases/user/getUser";
import { User } from "../types/User.model";

interface UserContextType {
  userData: User | null;
  isLoading: boolean;
  updateUserData: (data: any) => void;
  getUserData: () => Promise<void>;
  clearUserData: () => void;
  createDashboard: (
    dashboardName: string
  ) => Promise<DashboardEntity | undefined>;
}

export const UserContext = createContext<UserContextType>({
  userData: null,
  isLoading: false,
  updateUserData: () => {},
  getUserData: async () => {},
  clearUserData: () => {},
  createDashboard: async () => undefined,
});

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, isLoaded } = useUser();
  const { getToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState<User | null>(null);
  const userRepository = new UserRepository();
  useEffect(() => {
    const fetchUserData = async () => {
      if (user && !userData) {
        setIsLoading(true);
        const userId = user.id;
        try {
          const data = await getUser({ userId });
          setUserData(data);
          localStorage.setItem("userData", JSON.stringify(data));
          localStorage.setItem("userDataTimestamp", Date.now().toString());
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    const cachedUserData = localStorage.getItem("userData");
    const cachedUserDataTimestamp = localStorage.getItem("userDataTimestamp");
    if (!user && isLoaded) {
      setIsLoading(false);
      localStorage.removeItem("userData");
      localStorage.removeItem("userDataTimestamp");
      setUserData(null);
    }
    if (
      isLoaded &&
      cachedUserData &&
      cachedUserDataTimestamp &&
      Date.now() - parseInt(cachedUserDataTimestamp) < 1000 * 60
    ) {
      setUserData(JSON.parse(cachedUserData));
      setIsLoading(false);
    } else if (isLoaded) {
      fetchUserData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToken, user]);

  const clearUserData = () => {
    setUserData(null);
    localStorage.removeItem("userData");
    localStorage.removeItem("userDataTimestamp");
  };

  const updateUserData = async (data: any) => {
    setUserData(data);
    localStorage.setItem("userData", JSON.stringify(data));
    localStorage.setItem("userDataTimestamp", Date.now().toString());
    await userRepository.updateUser(data);
  };

  const getUserData = async () => {
    setIsLoading(true);
    const userId = user?.id || "";
    try {
      const data = await getUser({ userId });
      setUserData(data);
      localStorage.setItem("userData", JSON.stringify(data));
      localStorage.setItem("userDataTimestamp", Date.now().toString());
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const createDashboard = async (dashboardName: string) => {
    setIsLoading(true);
    if (!user) {
      throw new Error("User is not logged in");
    }
    try {
      const data = await createDashboardUseCase({
        userId: user.id,
        dashboardName,
      });
      await getUserData();
      return data;
    } catch (error) {
      console.error("Error creating dashboard:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        isLoading,
        updateUserData,
        getUserData,
        clearUserData,
        createDashboard,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): UserContextType => {
  if (!UserContext) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return React.useContext(UserContext);
};
