import "./index.scss";

const BASE_CLASS = "dia-WorkInProgress";

export default function WorkInProgress() {
  return (
    <div className={BASE_CLASS}>
      <h1 className={`${BASE_CLASS}-title`}>Work in Progress</h1>
      <p className={`${BASE_CLASS}-description`}>
        This page is still under construction.
      </p>
    </div>
  );
}
