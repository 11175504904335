import { CustomerDetailData } from "../../types/Customer.model";
import fromCustomerHTTPResponseToCustomerEntity from "../mappers/fromCustomerHTTPResponseToCustomerEntity";
import { apiClient } from "./apiClient";

const customerApi = {
  createCustomer: async ({
    dashboardId,
    customerData,
  }: {
    dashboardId: string;
    customerData: Partial<CustomerDetailData>;
  }): Promise<Partial<CustomerDetailData>> => {
    return apiClient
      .post(`/dashboard/${dashboardId}/user`, {
        customerData,
      })
      .then((response) => {
        return response.data as Partial<CustomerDetailData>;
      });
  },
  deleteCustomer: async ({
    dashboardId,
    customerId,
  }: {
    dashboardId: string;
    customerId: string;
  }): Promise<void> => {
    return apiClient
      .delete(`/customer/${customerId}/dashboard/${dashboardId}`)
      .then(() => {});
  },
  updateCustomer: async ({
    customerData,
  }: {
    customerData: Partial<CustomerDetailData>;
  }): Promise<CustomerDetailData> => {
    return apiClient
      .put("/customer", {
        customerData,
      })
      .then((response: any) => {
        const data = response.data;
        return fromCustomerHTTPResponseToCustomerEntity(
          data.customer as Partial<CustomerDetailData>
        );
      });
  },
};

export default customerApi;
