import { ChatResponseParsed } from "../../types/ChatResponse.model";
import { chatClient } from "./chatClient";

interface ChatResponse {
  id: number;
  message: string;
  action: string;
  conversation_fk: number;
  updated_at: string;
  created_at: string;
}

const chatApi = {
  sendMessage: async ({
    message,
  }: {
    message: string;
  }): Promise<ChatResponseParsed> => {
    return chatClient
      .post("/chat/1/", {
        message,
      })
      .then((response) => {
        if (response.status === 201) {
          const data = response.data as ChatResponse;
          const chatResponseParsed: ChatResponseParsed = {
            id: data.id,
            message: data.message,
            action: data.action,
            conversationFk: data.conversation_fk,
            updatedAt: data.updated_at,
            createdAt: data.created_at,
          };
          return chatResponseParsed;
        } else {
          throw new Error("Error fetching user data");
        }
      });
  },
};

export default chatApi;
