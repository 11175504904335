import { createBrowserRouter } from "react-router-dom";
import SignInPage from "../pages/Unauthenticated/signInPage";
import SignUpPage from "../pages/Unauthenticated/signUpPage";
import AuthLayout from "../authLayout";
import OnboardingPage from "../pages/Onboarding";
import WelcomePage from "../pages/Onboarding/Welcome";
import BasicInfoStep from "../pages/Onboarding/BasicInfo";
import DashboardLayout from "../pages/Dashboard/Layout";
import PeoplePage from "../pages/Dashboard/Customers";
import CompaniesPage from "../pages/Dashboard/Companies";
import TodosPage from "../pages/Dashboard/Todos";
import CalendarPage from "../pages/Dashboard/Calendar";
import PipelinePage from "../pages/Dashboard/Pipeline";
import CompletePage from "../pages/Onboarding/Complete";
import WorkInProgress from "../components/WorkInProgress";

export const router = createBrowserRouter([
  {
    path: "/sign-in",
    element: <SignInPage />,
    children: [
      {
        path: "factor-one",
      },
      {
        path: "sso-callback",
      },
    ],
  },
  {
    path: "/sign-up",
    element: <SignUpPage />,
  },
  {
    element: <AuthLayout />,
    children: [
      {
        element: <OnboardingPage />,
        children: [
          {
            path: "/onboarding",
            element: <WelcomePage />,
          },
          {
            path: "/onboarding/basic-info",
            element: <BasicInfoStep />,
          },
          {
            path: "/onboarding/complete",
            element: <CompletePage />,
          },
        ],
      },
      {
        element: <DashboardLayout />,
        children: [
          {
            path: "/",
            element: <PeoplePage />,
          },
          {
            path: "/companies",
            element: <CompaniesPage />,
          },
          {
            path: "/todos",
            element: <TodosPage />,
          },
          {
            path: "/calendar",
            element: <CalendarPage />,
          },
          {
            path: "/pipeline",
            element: <PipelinePage />,
          },
          {
            path: "/personal-space",
            element: <WorkInProgress />,
          },
        ],
      },
    ],
  },
]);
