import dashboardApi from "../../api/dashboardApi";
import { DashboardEntity } from "../../entities/DashboardEntity";

const dashboardRepository = {
  createDashboard: async ({
    userId,
    dashboardName,
  }: {
    userId: string;
    dashboardName: string;
  }) => {
    return dashboardApi.createDashboard({ dashboardName, userId });
  },
  deleteDashboard: async ({ dashboardId }: { dashboardId: string }) => {
    return dashboardApi.deleteDashboard({ dashboardId });
  },
  getDashboard: async ({ dashboardId }: { dashboardId: string }) => {
    return dashboardApi.getDashboard({ dashboardId });
  },
  updateDashboard: async ({
    dashboard,
  }: {
    dashboard: Partial<DashboardEntity>;
  }) => {
    return dashboardApi.updateDashboard({ dashboard });
  },
};

export default dashboardRepository;
