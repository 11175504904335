import { DashboardEntity } from "../entities/DashboardEntity";
import fromDashboardHTTPReponseToDashboardEntity from "../mappers/fromDashboardHTTPReponseToDashboardEntity";
import { apiClient } from "./apiClient";

const dashboardApi = {
  createDashboard: async ({
    userId,
    dashboardName,
  }: {
    userId: string;
    dashboardName: string;
  }): Promise<DashboardEntity> => {
    return apiClient
      .post("/dashboard", {
        userId,
        dashboard: {
          name: dashboardName,
        },
      })
      .then((response) => {
        return fromDashboardHTTPReponseToDashboardEntity(response.data);
      });
  },
  deleteDashboard: async ({
    dashboardId,
  }: {
    dashboardId: string;
  }): Promise<void> => {
    return apiClient.delete(`/dashboard/${dashboardId}`).then(() => {});
  },
  getDashboard: async ({
    dashboardId,
  }: {
    dashboardId: string;
  }): Promise<DashboardEntity> => {
    return apiClient.get(`/dashboard/${dashboardId}`).then((response) => {
      return fromDashboardHTTPReponseToDashboardEntity(response.data);
    });
  },
  updateDashboard: async ({
    dashboard,
  }: {
    dashboard: Partial<DashboardEntity>;
  }): Promise<DashboardEntity> => {
    return apiClient
      .put("/dashboard", {
        dashboard,
      })
      .then((response) => {
        return fromDashboardHTTPReponseToDashboardEntity(response.data);
      });
  },
};

export default dashboardApi;
