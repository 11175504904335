import { useSearchParams } from "react-router-dom";
import { useUserContext } from "../../../contexts/UserContext";
import Input from "../../Input";
import Modal from "../../Modal";
import "./index.scss";

export default function NewCreateDashboardModal({
  show,
  onClose,
  isNew = false,
}: {
  show: boolean;
  onClose: () => void;
  isNew?: boolean;
}) {
  const [_, setSearchParams] = useSearchParams();
  const { createDashboard } = useUserContext();

  const handleCreateDashboard = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const dashboardName = formData.get("dashboardName") as string;
    createDashboard(dashboardName).then((res) => {
      setSearchParams({ dashboardId: res?.id || "" });
      onClose();
    });
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      onPrimaryClick={() => {}}
      onSecondaryClick={() => {
        onClose();
      }}
      primaryText="Create"
      secondaryText="Cancel"
      title={isNew ? "Create your first dashboard" : "Create a new dashboard"}
      subtitle="Create a new dashboard by filling the information below"
      onSubmit={(e) => {
        handleCreateDashboard(e);
      }}
    >
      <Input
        type="text"
        placeholder="Dashboard name"
        name="dashboardName"
        label="Dashboard name"
      />
    </Modal>
  );
}
