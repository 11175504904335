import "./index.scss";

import Button from "../../../../../components/Button";
import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { GmailResponse } from "../../../../../types/Gmail.model";
import getGmailEmails from "../../../../../services/useCases/google/getGmailEmails";
import { LoadingPage } from "../../../../../components/Spinner";

const BASE_CLASS = "dia-DashboardCustomerDetail-InteractionsSection";

export default function InteractionSection({ email }: { email: string }) {
  const { userId } = useAuth();

  const [emails, setEmails] = useState<GmailResponse[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchEmails = async () => {
    const response = await getGmailEmails({
      userId: userId as string,
      from: email,
    });
    setLoading(false);
    setEmails(response);
  };

  useEffect(() => {
    if (loading) {
      fetchEmails();
    }
  }, []);

  return loading ? (
    <LoadingPage />
  ) : emails && emails.length > 0 ? (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-header`}>
        <h2>Interactions</h2>
        <Button
          onClick={() =>
            window.open(
              `https://mail.google.com/mail/u/0/#search/from:${email}`,
              "_blank"
            )
          }
          variant="secondary"
        >
          View all
        </Button>
      </div>
      <div className={`${BASE_CLASS}-emails`}>
        {emails.map((email) => (
          <EmailCard key={email.id} email={email} />
        ))}
      </div>
    </div>
  ) : (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-header`}>
        <h2>Interactions</h2>
        <Button
          onClick={() =>
            window.open(
              `https://mail.google.com/mail/u/0/#search/from:${email}`,
              "_blank"
            )
          }
          className={`${BASE_CLASS}-header-button`}
        >
          View all
        </Button>
      </div>
      <div className={`${BASE_CLASS}-emails`}>
        <p>No interactions found</p>
      </div>
    </div>
  );
}

function EmailCard({ email }: { email: GmailResponse }) {
  const handleOpenEmail = () => {
    window.open(
      `https://mail.google.com/mail/u/0/#inbox/${email.id}`,
      "_blank"
    );
  };

  return (
    <div className="email-card" onClick={handleOpenEmail}>
      <div className="email-card-header">
        <div className="email-card-header-info">
          <span className="email-card-header-from">{email.from}</span>
          <span className="email-card-header-to">{email.to}</span>
        </div>
        <span className="email-card-header-date">{email.date}</span>
      </div>

      <div className="email-card-subject">{email.subject}</div>

      <div
        className="email-card-content"
        dangerouslySetInnerHTML={{ __html: formatEmailContent(email.content) }}
      ></div>
    </div>
  );
}

function formatEmailContent(content: string): string {
  // Convert URLs to clickable links
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const formattedContent = content
    .replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`)
    // Add a new line after greetings, signatures, etc.
    .replace(
      /(Hola\s\w+,|Saludos,|Un abrazo,|PD:|Atentamente,|Gracias,)/g,
      "$1<br/><br/>"
    )
    // Add breaks between paragraphs (detects new lines and adds <br/>)
    .replace(/\n/g, "<br/>")
    // Handle bullet points or enumerations (can be customized further)
    .replace(/(\*\s|\d+\.\s)/g, "<br/>$1")
    // Add some spacing for inline quotes or forwarded messages
    .replace(
      /(On\s.+wrote:|El\s\w+,\s\d+\s.+)/g,
      "<br/><strong>$1</strong><br/>"
    );

  return formattedContent;
}
