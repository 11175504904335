import "./index.scss";

const BASE_CLASS = "dia-TextArea";

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
}

export default function TextArea({
  value,
  onChange,
  placeholder,
  ...props
}: TextAreaProps) {
  return (
    <div className={BASE_CLASS}>
      <textarea
        className={`${BASE_CLASS}-input`}
        value={value}
        onChange={(e) => onChange(e)}
        placeholder={placeholder}
        style={{ width: "100%" }}
        {...props}
      />
    </div>
  );
}
