const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000"
    : "https://api-daialog.vercel.app";

export class UserRepository {
  async save(user: any): Promise<void> {
    // save user
  }

  async updateUser(user: any): Promise<void> {
    const response = await fetch(`${BASE_URL}/user/${user.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    return response.json();
  }

  async findUserById(userId: string, token: string): Promise<any> {
    try {
      const response = await fetch(`${BASE_URL}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      localStorage.setItem("userData", JSON.stringify(data));
      return data;
    } catch (e: any) {
      throw new Error(e);
    }
  }
}
