import { AiOutlinePlus } from "react-icons/ai";
import Button from "../../../../../components/Button";
import { Notes } from "../../../../../types/Customer.model";
import "./index.scss";
import { useState } from "react";
import Modal from "../../../../../components/Modal";
import TextArea from "../../../../../components/TextArea";

const BASE_CLASS = "dia-DashboardCustomerDetail-NotesSection";

export default function NotesSection({ notes }: { notes: Notes }) {
  const [showAddNote, setShowAddNote] = useState(false);
  return (
    <div className={BASE_CLASS}>
      <Modal
        show={showAddNote}
        onClose={() => setShowAddNote(false)}
        title="Add new note"
        onSecondaryClick={() => {
          setShowAddNote(false);
        }}
        onSubmit={() => {
          console.log("submit");
        }}
        primaryText={"Create"}
        secondaryText={"Cancel"}
      >
        <div>
          <TextArea
            placeholder="Type your note here"
            rows={5}
            style={{ width: "100%" }}
            onChange={(e) => console.log(e.target.value)}
          />
        </div>
      </Modal>
      <div className={`${BASE_CLASS}-header`}>
        <Button
          variant="primary"
          type="button"
          onClick={() => {
            setShowAddNote(true);
          }}
        >
          <AiOutlinePlus />
          Add new note
        </Button>
      </div>
    </div>
  );
}
