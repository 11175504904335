import "./index.scss";

import { useDashboardContext } from "../../../contexts/DashboardContext";
import { useUserContext } from "../../../contexts/UserContext";
import Input from "../../Input";
import Modal from "../../Modal";
import { useState } from "react";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useSearchParams } from "react-router-dom";

const BASE_CLASS = "dia-NewConfigDashboardModal";

export default function NewConfigDashboardModal({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const { getUserData } = useUserContext();
  const [_, setSearchParams] = useSearchParams();
  const { dashboard, updateDashboard, deleteDashboard } = useDashboardContext();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleUpdateDashboard = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const dashboardName = formData.get("dashboardName") as string;
    await updateDashboard({
      ...dashboard,
      name: dashboardName,
    }).then(async () => {
      getUserData().then(() => onClose());
    });
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      onPrimaryClick={() => {}}
      onSecondaryClick={() => {
        onClose();
      }}
      primaryText="Update"
      secondaryText="Cancel"
      title={`Update ${dashboard?.name}`}
      subtitle="Update the dashboard name by filling the information below"
      onSubmit={(e) => {
        handleUpdateDashboard(e);
      }}
      tertiaryText={
        <>
          <DeleteOutlineIcon />
          Borrar dashboard
        </>
      }
      onTertiaryClick={() => {
        setShowDeleteModal(true);
      }}
    >
      <Modal
        withForm={false}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSecondaryClick={() => setShowDeleteModal(false)}
        primaryText="Delete"
        secondaryText="Cancel"
        title="Delete dashboard?"
        subtitle="Are you sure you want to delete this dashboard?"
        onPrimaryClick={() => {
          deleteDashboard(dashboard?.id || "").then(() => {
            setShowDeleteModal(false);
            getUserData().then(() => {
              setSearchParams({ dashboardId: "" });
              onClose();
            });
          });
        }}
      />
      <div className={BASE_CLASS}>
        <Input
          type="text"
          placeholder={dashboard?.name}
          name="dashboardName"
          label="Dashboard name"
        />
      </div>
    </Modal>
  );
}
