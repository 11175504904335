import OnboardingChat from "./Chat";
import "./index.scss";

const BASE_CLASS = "dia-OnboardingPage";

export default function BasicInfoStep() {
  return (
    <div className={BASE_CLASS}>
      <OnboardingChat />
    </div>
  );
}
