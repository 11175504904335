import {
  CustomerDetailData,
  Interactions,
  Notes,
  SocialMedia,
  Summaries,
} from "../../types/Customer.model";

export class CustomerDetailEntity {
  public id: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public position: string;
  public company: string;
  public profilePicture: string;
  public socialMedia: SocialMedia;
  public interactions: Interactions;
  public notes: Notes;
  public summaries: Summaries;
  public todos: string[];
  public meetings: {
    total: number;
    meetings: {
      id: string;
      date: Date;
      content: string;
    }[];
  };

  constructor(customerDetailData: CustomerDetailData) {
    this.id = customerDetailData.id;
    this.firstName = customerDetailData.firstName;
    this.lastName = customerDetailData.lastName;
    this.email = customerDetailData.email;
    this.phone = customerDetailData.phone;
    this.position = customerDetailData.position;
    this.company = customerDetailData.company;
    this.profilePicture = customerDetailData.profilePicture;
    this.socialMedia = customerDetailData.socialMedia;
    this.interactions = customerDetailData.interactions;
    this.notes = customerDetailData.notes;
    this.summaries = customerDetailData.summaries;
    this.todos = customerDetailData.todos;
    this.meetings = customerDetailData.meetings;
  }
}
