export interface ChatClientOptions {
  headers?: HeadersInit;
  body?: any;
  queryParams?: Record<string, string | number | boolean>;
}

export interface ChatResponse<T> {
  data: T;
  status: number;
  statusText: string;
}

const BASE_URL = "https://llm.daialog.app"; // Replace with your API base URL

const buildUrl = (
  endpoint: string,
  queryParams?: Record<string, string | number | boolean>
): string => {
  const url = new URL(`${BASE_URL}${endpoint}`);
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      url.searchParams.append(key, value.toString());
    });
  }
  return url.toString();
};

const request = async <T>(
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
  endpoint: string,
  { headers, body, queryParams }: ChatClientOptions = {}
): Promise<ChatResponse<T>> => {
  const url = buildUrl(endpoint, queryParams);

  const fetchOptions: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token a8c674d63423c9c98c52b6c5d6af7259f4aa8bb5",
      ...headers,
    },
    body: body ? JSON.stringify(body) : undefined,
  };

  try {
    const response = await fetch(url, fetchOptions);

    const data = (await response.json()) as T;

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return {
      data,
      status: response.status,
      statusText: response.statusText,
    };
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const chatClient = {
  get: <T>(endpoint: string, options?: ChatClientOptions) =>
    request<T>("GET", endpoint, options),

  post: <T>(endpoint: string, body: any, options?: ChatClientOptions) =>
    request<T>("POST", endpoint, { ...options, body }),

  put: <T>(endpoint: string, body: any, options?: ChatClientOptions) =>
    request<T>("PUT", endpoint, { ...options, body }),

  delete: <T>(endpoint: string, options?: ChatClientOptions) =>
    request<T>("DELETE", endpoint, options),

  patch: <T>(endpoint: string, body: any, options?: ChatClientOptions) =>
    request<T>("PATCH", endpoint, { ...options, body }),
};
