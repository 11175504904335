import { CustomerDetailData } from "../../../../types/Customer.model";
import customerRepository from "../../../repositories/dashboard/customerRepository";

export default function updateCustomer({
  customerData,
}: {
  customerData: Partial<CustomerDetailData>;
}): Promise<CustomerDetailData> {
  return customerRepository.updateCustomer({ customerData });
}
