import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo/logo.png";

import "./index.scss";
import Button from "../../../components/Button";

const BASE_CLASS = "dia-WelcomePage";

export default function WelcomePage() {
  const navigate = useNavigate();

  const onNext = () => {
    navigate("/onboarding/basic-info");
  };

  return (
    <div className={`${BASE_CLASS}`}>
      <h2 className={`${BASE_CLASS}-title`}>Welcome to the AI generation</h2>
      <img src={logo} alt="DiaLog logo" className={`${BASE_CLASS}-logo`} />
      <p className={`${BASE_CLASS}-text`}>
        Hey! I’m Richard, your personal AI assistant for your CRM, let’s
        configure me.
      </p>
      <Button onClick={onNext}>
        <span className={`${BASE_CLASS}-button-label`}>Start</span>
      </Button>
    </div>
  );
}
