import customerRepository from "../../../repositories/dashboard/customerRepository";

export default function deleteCustomer({
  dashboardId,
  customerId,
}: {
  dashboardId: string;
  customerId: string;
}): Promise<void> {
  return customerRepository.deleteCustomer({ dashboardId, customerId });
}
