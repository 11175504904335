import { CustomerDetailData } from "../../../../types/Customer.model";
import customerRepository from "../../../repositories/dashboard/customerRepository";

export default function createCustomer({
  dashboardId,
  customerData,
}: {
  dashboardId: string;
  customerData: Partial<CustomerDetailData>;
}): Promise<Partial<CustomerDetailData>> {
  return customerRepository.createCustomer({
    dashboardId,
    customerData,
  });
}
