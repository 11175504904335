import "./index.scss";

import Pipeline from "../../../components/Pipeline";
import { PipelineInterface } from "../../../components/Pipeline/interfaces";
import { useState } from "react";

export default function PipelinePage() {
  const samplePipeline: PipelineInterface = {
    id: "1",
    name: "Sales Pipeline",
    description: "Track deals and progress through stages",
    stages: [
      { id: "stage-1", name: "Lead", description: "Leads coming in" },
      { id: "stage-2", name: "Negotiation", description: "In discussion" },
      { id: "stage-3", name: "Closed", description: "Deal closed" },
    ],
    items: [
      {
        id: "item-1",
        name: "Deal 1",
        description: "Potential deal",
        stageId: "stage-1",
        date: "2023-09-15",
      },
      {
        id: "item-2",
        name: "Deal 2",
        description: "Negotiation ongoing",
        stageId: "stage-2",
        label: "High priority",
      },
    ],
  };

  const [pipeline, setPipeline] = useState<PipelineInterface>(samplePipeline);

  return (
    <div className="dia-DashboardPipeline">
      <Pipeline pipeline={pipeline} setPipeline={setPipeline} />
    </div>
  );
}
