import { useEffect, useRef, useState } from "react";
import Sortable from "sortablejs";
import "./index.scss";
import { PipelineProps } from "./interfaces";
import PipelineCard from "./components/PipelineCard";
import Button from "../Button";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import Modal from "../Modal";

const BASE_CLASS = "dia-Pipeline";

export default function Pipeline({ pipeline, setPipeline }: PipelineProps) {
  const stageRefs = useRef<HTMLDivElement[]>([]);
  const [showCreateStageModal, setShowCreateStageModal] = useState(false);
  const [showCreateItemModal, setShowCreateItemModal] = useState(false);

  useEffect(() => {
    stageRefs.current.forEach((stageRef) => {
      if (stageRef) {
        Sortable.create(stageRef, {
          group: "shared",
          animation: 150,
          draggable: ".dia-PipelineCard", // Only elements with this class will be draggable
          onEnd: (event) => {
            const { oldIndex, newIndex } = event;
          },
        });
      }
    });
  }, [pipeline, setPipeline]);

  return (
    <div className={BASE_CLASS}>
      <Modal
        show={showCreateStageModal}
        onClose={() => setShowCreateStageModal(false)}
        onSecondaryClick={() => {
          setShowCreateStageModal(false);
        }}
        primaryText="Create"
        secondaryText="Cancel"
        title="Create Stage"
      >
        <div>
          <h1>Create Stage</h1>
          <input type="text" placeholder="Name" />
          <textarea placeholder="Description" />
        </div>
      </Modal>
      <Modal
        show={showCreateItemModal}
        onClose={() => setShowCreateItemModal(false)}
        onSecondaryClick={() => {
          setShowCreateItemModal(false);
        }}
        primaryText="Create"
        secondaryText="Cancel"
        title="Create Stage"
      >
        <div>
          <h1>Create Item</h1>
          <input type="text" placeholder="Name" />
          <textarea placeholder="Description" />
        </div>
      </Modal>
      <div className={`${BASE_CLASS}-header`}>
        <h1 className={`${BASE_CLASS}-title`}>{pipeline.name}</h1>
        <p className={`${BASE_CLASS}-description`}>{pipeline.description}</p>
        <Button onClick={() => setShowCreateStageModal(true)} variant="primary">
          Create Stage
        </Button>
      </div>
      <div className={`${BASE_CLASS}-stages`}>
        {pipeline.stages.map((stage, index) => (
          <div
            key={stage.id}
            id={stage.id}
            className={`${BASE_CLASS}-stage`}
            ref={(el) => (stageRefs.current[index] = el!)}
          >
            <div className={`${BASE_CLASS}-stage-header`}>
              <h2 className={`${BASE_CLASS}-stage-title`}>
                {stage.name}
                <span className={`${BASE_CLASS}-stage-title-count`}>
                  (
                  {
                    pipeline.items.filter((item) => item.stageId === stage.id)
                      .length
                  }
                  )
                </span>
              </h2>
              <div className={`${BASE_CLASS}-stage-header-actions`}>
                <span
                  className={`${BASE_CLASS}-stage-create-item`}
                  onClick={() => setShowCreateItemModal(true)}
                >
                  +
                </span>
                <DeleteOutlineOutlined />
              </div>
            </div>
            {pipeline.items
              .filter((item) => item.stageId === stage.id)
              .map((item) => (
                <PipelineCard key={item.id} pipelineItem={item} />
              ))}
          </div>
        ))}
      </div>
    </div>
  );
}
