import "./index.scss";
import cx from "classnames";
import React, { ReactNode } from "react";

const buttonVariants = {
  primary: "primary",
  secondary: "secondary",
  danger: "danger",
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  disabled?: boolean;
  variant?: keyof typeof buttonVariants;
}

const BASE_CLASS = "dia-Button";

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  disabled = false,
  variant = "primary",
  ...props
}) => {
  return (
    <button
      className={cx(BASE_CLASS, {
        [`${BASE_CLASS}--${buttonVariants[variant]}`]: variant,
      })}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
