import { v4 as uuid } from "uuid";

import { useAuth, useUser } from "@clerk/clerk-react";
import { createContext, useContext, useEffect, useState } from "react";
import { Dashboard } from "../types/Dashboard.model";
import getDashboard from "../services/useCases/dashboard/getDashboard";
import deleteDashboardUseCase from "../services/useCases/dashboard/deleteDashboard";
import updateDashboardUseCase from "../services/useCases/dashboard/updateDashboard";
import deleteCustomer from "../services/useCases/dashboard/customers/deleteCustomer";
import { useSearchParams } from "react-router-dom";
import createCustomer from "../services/useCases/dashboard/customers/createCustomer";

export const DashboardContext = createContext<{
  dashboard: Dashboard | null;
  isLoading: boolean;
  dashboardId: string;
  createUser: (user: {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    phone?: string;
  }) => Promise<void>;
  updateDashboard: (dashboard: any) => Promise<void>;
  deleteDashboard: (dashboardId: string) => Promise<void>;
  fetchDashboard: () => Promise<void>;
  deleteCustomerHandler: ({
    customerId,
    dashboardId,
  }: {
    customerId: string;
    dashboardId: string;
  }) => Promise<void>;
}>({
  dashboardId: "",
  dashboard: null,
  isLoading: false,
  createUser: async () => {},
  updateDashboard: async () => {},
  deleteDashboard: async () => {},
  fetchDashboard: async () => {},
  deleteCustomerHandler: async () => {},
});

export const DashboardProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useUser();
  const { getToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [dashboard, setDashboard] = useState<Dashboard | null>(null);
  const dashboardId = searchParams.get("dashboardId") || "";

  const fetchDashboard = async () => {
    if (user && dashboardId) {
      setIsLoading(true);
      try {
        const data = await getDashboard({ dashboardId });
        setDashboard(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (dashboard && dashboard?.id !== dashboardId) {
      setDashboard(null);
      fetchDashboard();
    }

    if (!dashboard && user) {
      fetchDashboard();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToken, user, dashboardId]);

  const createUser = async ({
    email,
    firstName,
    lastName,
    company,
    phone,
  }: {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    phone?: string;
  }) => {
    const id = uuid();
    const customerData = {
      id,
      email,
      firstName,
      lastName,
      company,
      phone,
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
    };

    if (user) {
      await createCustomer({ dashboardId, customerData });
      fetchDashboard();
    }
  };

  const updateDashboard = async (dashboardData: any) => {
    if (user) {
      const dataToUpdate = {
        id: dashboardData.id,
        name: dashboardData.name,
        updatedAt: new Date().toISOString(),
      };
      await updateDashboardUseCase({ dashboard: dataToUpdate });
      fetchDashboard();
    }
  };

  const deleteDashboard = async (dashboardId: string) => {
    if (user) {
      await deleteDashboardUseCase({ dashboardId });
      fetchDashboard();
    }
  };

  const deleteCustomerHandler = async ({
    customerId,
    dashboardId,
  }: {
    customerId: string;
    dashboardId: string;
  }) => {
    if (user) {
      await deleteCustomer({ customerId, dashboardId });
      fetchDashboard();
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        dashboard,
        dashboardId,
        isLoading,
        createUser,
        updateDashboard,
        deleteDashboard,
        fetchDashboard,
        deleteCustomerHandler,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error(
      "useDashboardContext must be used within a DashboardProvider"
    );
  }
  return context;
};
