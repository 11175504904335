import { useAuth } from "@clerk/clerk-react";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "./contexts/UserContext";
import { LoadingPage } from "./components/Spinner";

export default function AuthLayout() {
  const location = useLocation();
  const { userId, isLoaded } = useAuth();
  const { userData, isLoading } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      if (
        isLoaded &&
        !isLoading &&
        !userData.hasCreatedProfile &&
        !location.pathname.includes("/onboarding")
      ) {
        navigate("/onboarding");
      }
    }
    if (isLoaded && !userId) {
      navigate("/sign-in");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, userData, userId]);

  if (!isLoaded && !userData) return <LoadingPage />;

  return <Outlet />;
}
