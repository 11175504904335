import { User } from "../../types/User.model";
import fromUserHTTPResponseToUserEntity from "../mappers/fromUserHTTPResponseToUserEntity";
import { apiClient } from "./apiClient";

const userApi = {
  getUser: async ({ userId }: { userId: string }): Promise<User> => {
    return apiClient.get(`/user/${userId}`).then((response) => {
      if (response.status === 200) {
        return fromUserHTTPResponseToUserEntity(response.data);
      } else {
        throw new Error("Error fetching user data");
      }
    });
  },
  updateUser: async ({ userData }: { userData: User }): Promise<User> => {
    return apiClient
      .put("/user", {
        userData: {
          id: userData.id,
          email: userData.email,
          hasCreatedProfile: userData.hasCreatedProfile,
        },
      })
      .then((response) => {
        return fromUserHTTPResponseToUserEntity(response.data);
      });
  },
};

export default userApi;
