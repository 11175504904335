import { User } from "../../types/User.model";
import { UserEntity } from "../entities/UserEntity";

export default function fromUserHTTPResponseToUserEntity(
  userHTTPResponse: any
): User {
  return new UserEntity({
    assignedPlans: userHTTPResponse.assignedPlans,
    birthDate: userHTTPResponse.birthDate,
    companyName: userHTTPResponse.companyName,
    createdAt: userHTTPResponse.createdAt,
    dashboards: userHTTPResponse.dashboards,
    email: userHTTPResponse.email,
    fullName: userHTTPResponse.fullName,
    hasCreatedProfile: userHTTPResponse.hasCreatedProfile,
    id: userHTTPResponse.id,
    jobTitle: userHTTPResponse.jobTitle,
    phone: userHTTPResponse.phone,
    profilePicture: userHTTPResponse.profilePicture,
    stripeId: userHTTPResponse.stripeId,
    updatedAt: userHTTPResponse.updatedAt,
  });
}
