import { createRef, useEffect, useState } from "react";
import "./index.scss";
import { useChatContext } from "../../../contexts/ChatContext";
import { BsSend } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import Input from "../../Input";
const BASE_CLASS = "dia-Chat";

export default function Chat({ onClose }: { onClose: () => void }) {
  const [message, setMessage] = useState("");
  const { messages, isLoading, sendMessage } = useChatContext();
  const chatRef = createRef<HTMLDivElement>();

  useEffect(() => {
    const scrollToBottom = () => {
      chatRef.current?.scrollTo({
        top: chatRef.current.scrollHeight,
        behavior: "smooth",
      });
    };

    scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  return (
    <form
      onClick={(e) => {
        e.stopPropagation();
      }}
      onSubmit={async (e) => {
        e.preventDefault();
        if (message) {
          sendMessage(message);
          setMessage("");
        }
      }}
      className={`${BASE_CLASS}-container`}
    >
      <div className={`${BASE_CLASS}-header`}>
        <AiOutlineClose size={32} onClick={() => onClose()} />
      </div>
      <div ref={chatRef} className={`${BASE_CLASS}-messages`}>
        {messages.map((message, index) => (
          <div key={index} className={`${BASE_CLASS}-messageContainer`}>
            <p
              className={`${BASE_CLASS}-messageTime ${BASE_CLASS}-messageTime--${message.user}`}
            >
              {message.time ? new Date(message.time).toLocaleTimeString() : ""}
            </p>
            <div
              key={index}
              className={`${BASE_CLASS}-message ${BASE_CLASS}-message--${message.user}`}
            >
              {message.text}
            </div>
          </div>
        ))}
        {isLoading && (
          <div
            className={`${BASE_CLASS}-message ${BASE_CLASS}-message--loading`}
          >
            <span className={`${BASE_CLASS}-message--loading-dot`} />
          </div>
        )}
      </div>
      <div className={`${BASE_CLASS}-inputContainer`}>
        <Input
          type="text"
          placeholder="Type a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          type="submit"
          className={`${BASE_CLASS}-inputContainer-button ${
            message ? `${BASE_CLASS}-inputContainer-button--active` : ""
          }`}
        >
          <BsSend size={24} />
        </button>
      </div>
    </form>
  );
}
