import { DashboardEntity } from "../../entities/DashboardEntity";
import dashboardRepository from "../../repositories/dashboard/dashboardRepository";

export default function createDashboard({
  userId,
  dashboardName,
}: {
  userId: string;
  dashboardName: string;
}): Promise<DashboardEntity> {
  return dashboardRepository.createDashboard({
    userId,
    dashboardName,
  });
}
