import "./index.scss";
import { SignOutButton } from "@clerk/clerk-react";
import { Outlet } from "react-router-dom";

const BASE_CLASS = "dia-OnboardingPage";

export default function OnboardingPage() {
  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-header`}>
        <h1 className={`${BASE_CLASS}-header-title`}>
          D<span className={`${BASE_CLASS}-header-title-highlight`}>ai</span>
          log
        </h1>
        <SignOutButton>
          <button className={`${BASE_CLASS}-header-signout`}>Sign out</button>
        </SignOutButton>
      </div>
      <Outlet />
    </div>
  );
}
