import { User } from "../../../types/User.model";
import userRepository from "../../repositories/user/userRepository";

export default async function getUser({
  userId,
}: {
  userId: string;
}): Promise<User> {
  try {
    return userRepository.getUser({ userId });
  } catch (error: any) {
    throw new Error(error);
  }
}
