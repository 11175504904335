import "./index.scss";

const BASE_CLASS = "dia-OnboardingChatMessage";

export default function Message({
  message,
  isUser = false,
  profilePicture,
  name,
}: {
  message: string;
  isUser?: boolean;
  profilePicture?: string;
  name: string;
}) {
  return (
    <div className={`${BASE_CLASS} ${isUser ? `${BASE_CLASS}-user` : ""}`}>
      {profilePicture ? (
        <img
          src={profilePicture}
          alt={name}
          className={`${BASE_CLASS}-profilePicture`}
        />
      ) : (
        <div className={`${BASE_CLASS}-profilePicture-placeholder`}>
          {name[0]}
        </div>
      )}
      <div className={`${BASE_CLASS}-content`}>
        <div className={`${BASE_CLASS}-name`}>{name}</div>
        <div className={`${BASE_CLASS}-message`}>{message}</div>
      </div>
    </div>
  );
}
