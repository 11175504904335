import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo/logo.png";

import "./index.scss";
import Button from "../../../components/Button";

const BASE_CLASS = "dia-CompletePage";

export default function CompletePage() {
  const navigate = useNavigate();

  const onNext = () => {
    navigate("/");
  };

  return (
    <div className={`${BASE_CLASS}`}>
      <h2 className={`${BASE_CLASS}-title`}>Great Job Pol!</h2>
      <img src={logo} alt="DiaLog logo" className={`${BASE_CLASS}-logo`} />
      <p className={`${BASE_CLASS}-text`}>
        We've finished the questions. Next, we'll set up your dashboard with
        your information and I'll show you everything you can do.
      </p>
      <Button onClick={onNext}>
        <span className={`${BASE_CLASS}-button-label`}>Start</span>
      </Button>
    </div>
  );
}
