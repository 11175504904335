import { DashboardEntity } from "../entities/DashboardEntity";

export default function fromDashboardHTTPResponseToDashboardEntity(
  dashboardHTTPResponse: any
): DashboardEntity {
  return new DashboardEntity({
    id: dashboardHTTPResponse.id,
    name: dashboardHTTPResponse.name,
    calendars: dashboardHTTPResponse.calendars,
    companies: dashboardHTTPResponse.companies,
    customers: dashboardHTTPResponse.customers,
    notes: dashboardHTTPResponse.notes,
    todos: dashboardHTTPResponse.todos,
  });
}
