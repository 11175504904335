import Input from "../../../../components/Input";
import Modal from "../../../../components/Modal";
import { useDashboardContext } from "../../../../contexts/DashboardContext";
import "./index.scss";

const BASE_CLASS = "dia-DashboardCustomersPageCreateCustomerModal";

export default function CreateCustomerModal({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const { createUser } = useDashboardContext();

  const handleCreateCustomer = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const name = formData.get("name") as string;
    const surnames = formData.get("surnames") as string;
    const email = formData.get("email") as string;
    const company = formData.get("company") as string;
    const phone = formData.get("phone") as string;

    await createUser({
      email,
      firstName: name,
      lastName: surnames,
      company,
      phone,
    }).then(() => onClose());
  };
  return (
    <Modal
      show={show}
      onClose={onClose}
      onSecondaryClick={onClose}
      subtitle="Create a new contact in the people list by filling the information below. You’ll be able to add more information once the user is created. "
      primaryText="Save"
      secondaryText="Cancel"
      title="Create new customer"
      onSubmit={handleCreateCustomer}
    >
      <div className={`${BASE_CLASS}`}>
        <div className={`${BASE_CLASS}-name`}>
          <Input type="text" placeholder="Name" name="name" label="Name" />
          <Input
            type="text"
            placeholder="Surnames"
            name="surnames"
            label="Surnames"
          />
        </div>
        <Input type="text" placeholder="Email" name="email" label="Email" />
        <div className={`${BASE_CLASS}-info`}>
          <Input
            type="text"
            placeholder="Company"
            name="company"
            label="Company"
          />
          <Input type="text" placeholder="Phone" name="phone" label="Phone" />
        </div>
      </div>
    </Modal>
  );
}
