import React from "react";
import Button from "../../../../components/Button";
import "./index.scss";
import Message from "./Message";
import { useUserContext } from "../../../../contexts/UserContext";
import { useChatbot } from "../../../../hooks/useChatbot";
import { User } from "../../../../types/User.model";

const BASE_CLASS = "dia-OnboardingChat";

export default function OnboardingChat() {
  const { userData } = useUserContext();
  const {
    messages,
    inputValue,
    handleInputChange,
    handleSend,
    handleKeyPress,
  } = useChatbot(userData as User);

  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-messages`}>
        <div className={`${BASE_CLASS}-messages-container`}>
          {messages.map((message, index) => (
            <Message key={index} {...message} />
          ))}
        </div>
      </div>
      <div className={`${BASE_CLASS}-input`}>
        <input
          placeholder="Type your answer here..."
          type="text"
          className={`${BASE_CLASS}-input-field`}
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
        />
        <div className={`${BASE_CLASS}-input-send`}>
          <Button onClick={handleSend}>Send</Button>
          <span className={`${BASE_CLASS}-input-send-label`}>
            or press Enter
          </span>
        </div>
      </div>
    </div>
  );
}
