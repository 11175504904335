import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import { ClerkProvider } from "@clerk/clerk-react";
import { UserProvider } from "./contexts/UserContext";
import { ToastProvider } from "./contexts/ToastProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const PUBLISHABLE_KEY =
  "pk_test_d29ydGh5LXNpbGt3b3JtLTk0LmNsZXJrLmFjY291bnRzLmRldiQ";

async function deferRender() {
  if (process.env.REACT_APP_USE_MSW === "true") {
    const { worker } = await import("./mocks");
    await worker.start();
  }
}

deferRender().then(() => {
  root.render(
    <React.StrictMode>
      <ToastProvider>
        <ClerkProvider
          publishableKey={PUBLISHABLE_KEY}
          afterSignOutUrl="/sign-in"
        >
          <UserProvider>
            <RouterProvider router={router} />
          </UserProvider>
        </ClerkProvider>
      </ToastProvider>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
