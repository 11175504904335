import "./index.scss";
import logo from "../../assets/logo/logo.png";
import { useEffect, useState } from "react";
import Chat from "./Chat";
import { ChatProvider } from "../../contexts/ChatContext";
const BASE_CLASS = "dia-ChatBot";

export default function ChatBot() {
  const [openChat, setOpenChat] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpenChat(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <ChatProvider>
      <img
        src={logo}
        height={64}
        width={64}
        className={`${BASE_CLASS}-icon`}
        alt="chatbot"
        onClick={() => {
          setOpenChat(!openChat);
        }}
      />
      {openChat && (
        <div
          onClick={() => setOpenChat(false)}
          className={`${BASE_CLASS}-container`}
        >
          <Chat onClose={() => setOpenChat(false)} />
        </div>
      )}
    </ChatProvider>
  );
}
