import { Dashboard } from "../../types/Dashboard.model";
import { User } from "../../types/User.model";

export class UserEntity {
  assignedPlans: string[];
  birthDate?: Date;
  companyName: string;
  createdAt: number;
  dashboards: Dashboard[];
  email: string;
  fullName: string;
  hasCreatedProfile: boolean;
  id: string;
  jobTitle: string;
  phone?: string;
  profilePicture: string;
  stripeId: string;
  updatedAt: number;

  constructor(user: User) {
    this.assignedPlans = user.assignedPlans;
    this.birthDate = user.birthDate;
    this.companyName = user.companyName;
    this.createdAt = user.createdAt;
    this.dashboards = user.dashboards;
    this.email = user.email;
    this.fullName = user.fullName;
    this.hasCreatedProfile = user.hasCreatedProfile;
    this.id = user.id;
    this.jobTitle = user.jobTitle;
    this.phone = user.phone;
    this.profilePicture = user.profilePicture;
    this.stripeId = user.stripeId;
    this.updatedAt = user.updatedAt;
  }
}
