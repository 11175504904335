import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import { useDashboardContext } from "../../../contexts/DashboardContext";
import "./index.scss";
import CreateCompanyModal from "./CreateCompanyModal";
import Modal from "../../../components/Modal";
import CustomerDetail from "./CompanyDetail";
import { Customer } from "../../../types/Customer.model";
import { LoadingPage } from "../../../components/Spinner";

const BASE_CLASS = "dia-DashboardCompaniesPage";

export default function CompaniesPage() {
  const { dashboard } = useDashboardContext();
  const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();

  const [customersList, setCustomersList] = useState<Customer[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const BASE_URL =
    process.env.REACT_APP_STAGE === "development"
      ? "http://localhost:4000"
      : "https://api.daialog.app";

  useEffect(() => {
    fetch(`${BASE_URL}/company/${dashboard?.id}`).then((res) => {
      res
        .json()
        .then((data) => {
          setCustomersList(data.companies);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }, [BASE_URL, dashboard]);

  const handleOpenCustomerDetail = (customer: Customer) => {
    setSelectedCustomer(customer);
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <div className={BASE_CLASS}>
      <CreateCompanyModal
        show={showCreateCustomerModal}
        onClose={() => setShowCreateCustomerModal(false)}
      />
      <Modal
        show={!!selectedCustomer}
        onClose={() => {
          setSelectedCustomer(undefined);
        }}
        showHeader={false}
        onSecondaryClick={function (): void {
          setSelectedCustomer(undefined);
        }}
        primaryText={"Save"}
        secondaryText={"Close"}
        title={
          `${selectedCustomer?.firstName} ${selectedCustomer?.lastName}` || ""
        }
      >
        <CustomerDetail customer={selectedCustomer} />
      </Modal>
      <div className={`${BASE_CLASS}-header`}>
        <div className={`${BASE_CLASS}-header-info`}>
          <h1 className={`${BASE_CLASS}-header-info-title`}>Companies</h1>
          <span className={`${BASE_CLASS}-header-info-count`}>
            {customersList?.length || 0}
          </span>
        </div>
        <div className={`${BASE_CLASS}-header-actions`}>
          <Button onClick={() => {}} variant="secondary">
            Import
          </Button>
          <Button
            onClick={() => {
              setShowCreateCustomerModal(true);
            }}
            variant="primary"
          >
            + Add Company
          </Button>
        </div>
      </div>
      <div className={`${BASE_CLASS}-tableContainer`}>
        <table className={`${BASE_CLASS}-table`}>
          <thead className={`${BASE_CLASS}-tableHeader`}>
            <tr className={`${BASE_CLASS}-tableRow`}>
              <th className={`${BASE_CLASS}-tableHeaderCell`}>Name</th>
              <th className={`${BASE_CLASS}-tableHeaderCell`}>Email</th>
              <th className={`${BASE_CLASS}-tableHeaderCell`}>Company</th>
              <th className={`${BASE_CLASS}-tableHeaderCell`}>Phone</th>
            </tr>
          </thead>
          <tbody className={`${BASE_CLASS}-tableBody`}>
            {customersList?.map((customer) => (
              <tr
                onClick={() => handleOpenCustomerDetail(customer)}
                title="View customer"
                key={customer.id}
                className={`${BASE_CLASS}-tableRow`}
              >
                <td className={`${BASE_CLASS}-tableCell`}>
                  <div className={`${BASE_CLASS}-tableUserInfo`}>
                    <span className={`${BASE_CLASS}-tableAvatar`}>
                      {customer.firstName[0]}
                    </span>
                    {customer.firstName} {customer.lastName}
                  </div>
                </td>
                <td className={`${BASE_CLASS}-tableCell`}>{customer.email}</td>
                <td className={`${BASE_CLASS}-tableCell`}>
                  {customer.company}
                </td>
                <td className={`${BASE_CLASS}-tableCell`}>{customer.phone}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
