import { GmailResponse } from "../../../types/Gmail.model";
import gmailRepository from "../../repositories/gmail/gmailRepository";

export default async function getGmailEmails({
  userId,
  from,
}: {
  userId: string;
  from?: string;
}): Promise<GmailResponse[]> {
  try {
    return gmailRepository.getEmails({ userId, from });
  } catch (error: any) {
    throw new Error(error);
  }
}
