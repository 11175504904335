import { User } from "../../../types/User.model";
import userRepository from "../../repositories/user/userRepository";

export default async function updateUser({
  userData,
}: {
  userData: User;
}): Promise<User> {
  return userRepository.updateUser({ userData });
}
