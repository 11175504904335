import logo from "../../assets/logo/logo.png";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useUserContext } from "../../contexts/UserContext";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import ChecklistRtlOutlinedIcon from "@mui/icons-material/ChecklistRtlOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import SelfImprovementOutlinedIcon from "@mui/icons-material/SelfImprovementOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";

import "./index.scss";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { UserButton } from "@clerk/clerk-react";
import Button from "../Button";
import NewCreateDashboardModal from "./NewCreateDashboardModal";
import { createRef, useEffect, useState } from "react";
import NewConfigDashboardModal from "./NewConfigDashboardModal";

const BASE_CLASS = "dia-Navbar";

export default function Navbar() {
  const { userData } = useUserContext();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showCreateDashboardModal, setShowCreateDashboardModal] =
    useState(false);
  const [showConfigDashboardModal, setShowConfigDashboardModal] =
    useState(false);
  const dashboardId = searchParams.get("dashboardId") || "";

  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (!dashboardId && userData?.dashboards?.length) {
      setSearchParams({ dashboardId: userData?.dashboards[0]?.id });
    }
  }, [dashboardId, setSearchParams, userData]);

  useEffect(() => {
    // When user presses ⌘ + K, focus the search input
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "k" && e.metaKey) {
        e.preventDefault();
        inputRef.current?.focus();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  const getLinkWithDashboardId = (path: string) => {
    const currentSearchParams = new URLSearchParams(searchParams);
    currentSearchParams.set("dashboardId", dashboardId);
    return `${path}?${currentSearchParams.toString()}`;
  };

  return (
    <div className={BASE_CLASS}>
      <NewCreateDashboardModal
        show={showCreateDashboardModal}
        onClose={() => {
          setShowCreateDashboardModal(false);
        }}
      />
      <NewConfigDashboardModal
        show={showConfigDashboardModal}
        onClose={() => {
          setShowConfigDashboardModal(false);
        }}
      />
      <div className={`${BASE_CLASS}-topSide`}>
        <div className={`${BASE_CLASS}-header`}>
          <div className={`${BASE_CLASS}-header-title`}>
            <img
              className={`${BASE_CLASS}-header-logo`}
              src={logo}
              alt="logo"
            />
            {(userData?.dashboards?.length || 0) > 0 && (
              <select
                onChange={(e) => {
                  setSearchParams({ dashboardId: e.target.value });
                }}
                value={dashboardId}
                className={`${BASE_CLASS}-header-select`}
              >
                {userData?.dashboards?.map((dashboard) => (
                  <option key={dashboard.id} value={dashboard.id}>
                    {dashboard.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <Button
            onClick={() => {
              setShowCreateDashboardModal(true);
            }}
          >
            + Add
          </Button>
        </div>
        <div className={`${BASE_CLASS}-searchBox`}>
          <SearchOutlinedIcon
            fontSize="small"
            className={`${BASE_CLASS}-searchBox-icon`}
          />
          <input
            className={`${BASE_CLASS}-searchBox-input`}
            placeholder="Search..."
            ref={inputRef}
          />
          <span className={`${BASE_CLASS}-searchBox-helper`}>⌘ + K</span>
        </div>
        <div className={`${BASE_CLASS}-mainLinks`}>
          <Link
            to={getLinkWithDashboardId("/")}
            className={`${BASE_CLASS}-mainLinks-link`}
            style={{
              color: location.pathname === "/" ? "black" : "",
            }}
          >
            <PeopleAltOutlinedIcon fontSize="small" />
            <span>People</span>
          </Link>
          <Link
            style={{
              color: location.pathname === "/companies" ? "black" : "",
            }}
            to={getLinkWithDashboardId("/companies")}
            className={`${BASE_CLASS}-mainLinks-link`}
          >
            <BusinessOutlinedIcon fontSize="small" />
            <span>Companies</span>
          </Link>
          <Link
            to={getLinkWithDashboardId("/todos")}
            className={`${BASE_CLASS}-mainLinks-link`}
            style={{
              color: location.pathname === "/todos" ? "black" : "",
            }}
          >
            <ChecklistRtlOutlinedIcon fontSize="small" />
            <span>To do's</span>
          </Link>
          <Link
            to={getLinkWithDashboardId("/calendar")}
            className={`${BASE_CLASS}-mainLinks-link`}
            style={{
              color: location.pathname === "/calendar" ? "black" : "",
            }}
          >
            <CalendarMonthOutlinedIcon fontSize="small" />
            <span>Calendar</span>
          </Link>
          <Link
            to={getLinkWithDashboardId("/pipeline")}
            className={`${BASE_CLASS}-mainLinks-link`}
            style={{
              color: location.pathname === "/pipeline" ? "black" : "",
            }}
          >
            <RocketLaunchOutlinedIcon fontSize="small" />
            <span>Pipeline</span>
          </Link>
          <Link
            to={getLinkWithDashboardId("/personal-space")}
            className={`${BASE_CLASS}-mainLinks-link`}
            style={{
              color: location.pathname === "/personal-space" ? "black" : "",
            }}
          >
            <SelfImprovementOutlinedIcon fontSize="small" />
            <span>Personal space</span>
          </Link>
          <span
            className={`${BASE_CLASS}-mainLinks-link`}
            onClick={() => {
              setShowConfigDashboardModal(true);
            }}
            style={{
              color: showConfigDashboardModal ? "black" : "",
            }}
          >
            <SettingsOutlinedIcon fontSize="small" />
            <span>Dashboard settings</span>
          </span>
        </div>
      </div>
      <div className={`${BASE_CLASS}-bottomSide`}>
        <div className={`${BASE_CLASS}-bottomLinks`}>
          <span className={`${BASE_CLASS}-bottomLinks-link`}>
            <SettingsOutlinedIcon fontSize="small" />
            Settings
          </span>
          <span className={`${BASE_CLASS}-bottomLinks-link`}>
            <HelpOutlineOutlinedIcon fontSize="small" />
            Help
          </span>
          <span className={`${BASE_CLASS}-bottomLinks-link`}>
            <FeedbackOutlinedIcon fontSize="small" />
            Feedback
          </span>
        </div>
        <div className={`${BASE_CLASS}-profile`}>
          <UserButton />
          <p className={`${BASE_CLASS}-profile-name`}>{userData?.fullName}</p>
        </div>
      </div>
    </div>
  );
}
