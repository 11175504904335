import { PipelineItem } from "../../interfaces";
import "./index.scss";

const BASE_CLASS = "dia-PipelineCard";

export default function PipelineCard({
  pipelineItem,
}: {
  pipelineItem: PipelineItem;
}) {
  return (
    <div className={BASE_CLASS} id={pipelineItem.id}>
      <div className={`${BASE_CLASS}-header`}>
        <h2 className={`${BASE_CLASS}-header-title`}>{pipelineItem.name}</h2>
        <span className={`${BASE_CLASS}-header-date`}>{pipelineItem.date}</span>
      </div>
      {pipelineItem.label && (
        <span className={`${BASE_CLASS}-label`}>{pipelineItem.label}</span>
      )}
      <p className={`${BASE_CLASS}-description`}>{pipelineItem.description}</p>
    </div>
  );
}
