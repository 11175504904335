export interface ApiClientOptions {
  headers?: HeadersInit;
  body?: any;
  queryParams?: Record<string, string | number | boolean>;
}

export interface ApiResponse<T> {
  data: T;
  status: number;
  statusText: string;
}

console.log(process.env.REACT_APP_STAGE);

const BASE_URL =
  process.env.REACT_APP_STAGE === "development"
    ? "http://localhost:4000"
    : "https://api.daialog.app";

const buildUrl = (
  endpoint: string,
  queryParams?: Record<string, string | number | boolean>
): string => {
  const url = new URL(`${BASE_URL}${endpoint}`);
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      url.searchParams.append(key, value.toString());
    });
  }
  return url.toString();
};

const request = async <T>(
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
  endpoint: string,
  { headers, body, queryParams }: ApiClientOptions = {}
): Promise<ApiResponse<T>> => {
  const url = buildUrl(endpoint, queryParams);

  const fetchOptions: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: body ? JSON.stringify(body) : undefined,
  };

  try {
    const response = await fetch(url, fetchOptions);

    const data = (await response.json()) as T;

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return {
      data,
      status: response.status,
      statusText: response.statusText,
    };
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const apiClient = {
  get: <T>(endpoint: string, options?: ApiClientOptions) =>
    request<T>("GET", endpoint, options),

  post: <T>(endpoint: string, body: any, options?: ApiClientOptions) =>
    request<T>("POST", endpoint, { ...options, body }),

  put: <T>(endpoint: string, body: any, options?: ApiClientOptions) =>
    request<T>("PUT", endpoint, { ...options, body }),

  delete: <T>(endpoint: string, options?: ApiClientOptions) =>
    request<T>("DELETE", endpoint, options),

  patch: <T>(endpoint: string, body: any, options?: ApiClientOptions) =>
    request<T>("PATCH", endpoint, { ...options, body }),
};
