import { ChatResponseParsed } from "../../../types/ChatResponse.model";
import chatApi from "../../api/chatApi";

const chatRepository = {
  sendMessage: async ({
    message,
  }: {
    message: string;
  }): Promise<ChatResponseParsed> => {
    return chatApi.sendMessage({ message });
  },
};

export default chatRepository;
