import { useState } from "react";
import FileUpload from "../../../../components/FileUpload";
import Modal from "../../../../components/Modal";
import "./index.scss";
import { useSearchParams } from "react-router-dom";
import { useDashboardContext } from "../../../../contexts/DashboardContext";
import Papa from "papaparse";
import * as XLSX from "xlsx";

const BASE_CLASS = "dia-DashboardCustomersPage-ImportDataModal";

export default function ImportDataModal({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const [file, setFile] = useState<File | null>(null);
  const [searchParams] = useSearchParams();
  const dashboardId = searchParams.get("dashboardId") || "";
  const { fetchDashboard } = useDashboardContext();
  const handleFileChange = (file: File | null) => {
    setFile(file);
  };

  const handleImportCustomersData = async () => {
    if (!file) {
      return;
    }

    let jsonFile;

    // Check file type and handle parsing accordingly
    if (file.type === "application/json") {
      // For JSON files
      jsonFile = JSON.parse(await file.text());
    } else if (file.type === "text/csv") {
      // For CSV files
      const text = await file.text();
      jsonFile = Papa.parse(text, {
        header: true, // This assumes the first row contains headers, otherwise set to false
        skipEmptyLines: true,
      }).data;
    } else if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.name.endsWith(".xlsx")
    ) {
      // For XLSX files
      const arrayBuffer = await file.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });

      // Assuming we want the first sheet
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert the sheet to JSON
      jsonFile = XLSX.utils.sheet_to_json(sheet, { defval: null });
    } else {
      console.error("Unsupported file format");
      return;
    }

    const BASE_URL =
      process.env.NODE_ENV === "development"
        ? "http://localhost:4000"
        : "https://api-daialog.app";

    fetch(`${BASE_URL}/import/customers/${dashboardId}`, {
      method: "POST",
      body: JSON.stringify(jsonFile),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async () => {
      await fetchDashboard();
      onClose();
    });
  };
  return (
    <Modal
      show={show}
      onClose={onClose}
      onSecondaryClick={onClose}
      subtitle="Update a json file with customer data"
      primaryText="Save"
      secondaryText="Cancel"
      title="Import Customer Data"
      withForm={false}
      onPrimaryClick={handleImportCustomersData}
    >
      <div className={BASE_CLASS}>
        <FileUpload onFileChange={handleFileChange} />
      </div>
    </Modal>
  );
}
