// Create a toast provider that will be used to display toasts

import { createContext, useContext, useState } from "react";
import Notification from "../components/Notification";

interface Toast {
  id: string;
  message: string;
  title: string;
  type: "success" | "error" | "warning";
}

interface ToastContextType {
  addToast: (toast: Toast) => void;
  removeToast: (id: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toast, setToast] = useState<Toast>();

  const addToast = (toast: Toast) => {
    setToast(toast);
    setTimeout(() => {
      removeToast();
    }, 2000);
  };

  const removeToast = () => {
    setToast(undefined);
  };

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      {toast && (
        <Notification
          title={toast?.title || ""}
          message={toast?.message || ""}
          type={toast?.type || "success"}
        />
      )}
    </ToastContext.Provider>
  );
};

function useToast() {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
}

export { ToastProvider, useToast };
