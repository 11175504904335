import { useState } from "react";
import Button from "../../../../../components/Button";
import Input from "../../../../../components/Input";
import { CustomerDetailData } from "../../../../../types/Customer.model";
import "./index.scss";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import { useDashboardContext } from "../../../../../contexts/DashboardContext";
import updateCustomer from "../../../../../services/useCases/dashboard/customers/updateCustomer";
import { useToast } from "../../../../../contexts/ToastProvider";

const BASE_CLASS = "dia-DashboardCustomersPage-EditProfilePage";

export default function EditProfilePage({
  customerDataProps,
  onClose,
  setCustomerDetailDataProps,
}: {
  customerDataProps: CustomerDetailData;
  setCustomerDetailDataProps: React.Dispatch<
    React.SetStateAction<CustomerDetailData | undefined>
  >;
  onClose: () => void;
}) {
  const [customerDetailData, setCustomerDetailData] =
    useState<CustomerDetailData>(customerDataProps);
  const { deleteCustomerHandler, dashboardId } = useDashboardContext();
  const { addToast } = useToast();

  const handleUpdateCustomer = async () => {
    await updateCustomer({
      customerData: customerDetailData,
    })
      .then((res) => {
        setCustomerDetailDataProps(res);
        addToast({
          id: "update-customer",
          title: "Customer updated",
          message: "Customer updated successfully",
          type: "success",
        });
      })
      .catch(() => {
        addToast({
          id: "update-customer",
          title: "Error updating customer",
          message: "There was an error updating the customer",
          type: "error",
        });
      });
  };

  const handleDeleteCustomer = async () => {
    await deleteCustomerHandler({
      customerId: customerDetailData.id,
      dashboardId: dashboardId,
    }).then(() => {
      onClose();
    });
  };

  return (
    <div className={BASE_CLASS}>
      <div className={`${BASE_CLASS}-name`}>
        <Input
          type="text"
          placeholder="Name"
          name="name"
          label="Name"
          value={customerDetailData.firstName}
          onChange={(e) =>
            setCustomerDetailData({
              ...customerDetailData,
              firstName: e.target.value,
            })
          }
        />
        <Input
          type="text"
          placeholder="Surnames"
          name="surnames"
          label="Surnames"
          value={customerDetailData.lastName}
          onChange={(e) =>
            setCustomerDetailData({
              ...customerDetailData,
              lastName: e.target.value,
            })
          }
        />
      </div>
      <Input
        type="text"
        placeholder="Email"
        name="email"
        label="Email"
        value={customerDetailData.email}
        onChange={(e) =>
          setCustomerDetailData({
            ...customerDetailData,
            email: e.target.value,
          })
        }
      />
      <div className={`${BASE_CLASS}-info`}>
        <Input
          type="text"
          placeholder="Company"
          name="company"
          label="Company"
          value={customerDetailData.company}
          onChange={(e) =>
            setCustomerDetailData({
              ...customerDetailData,
              company: e.target.value,
            })
          }
        />
        <Input
          type="text"
          placeholder="Phone"
          name="phone"
          label="Phone"
          value={customerDetailData.phone}
          onChange={(e) =>
            setCustomerDetailData({
              ...customerDetailData,
              phone: e.target.value,
            })
          }
        />
      </div>
      <div className={`${BASE_CLASS}-buttons`}>
        <Button onClick={handleUpdateCustomer} type="button">
          Update Profile
        </Button>
        <Button variant="danger" onClick={handleDeleteCustomer}>
          <DeleteOutlineOutlined />
          Delete Customer
        </Button>
      </div>
    </div>
  );
}
