import "./Layout.scss";
import { Outlet } from "react-router-dom";
import ChatBot from "../../components/ChatBot";
import { DashboardProvider } from "../../contexts/DashboardContext";
import Navbar from "../../components/Navbar";
import { useUserContext } from "../../contexts/UserContext";
import NewCreateDashboardModal from "../../components/Navbar/NewCreateDashboardModal";
import { useEffect, useState } from "react";
import { LoadingPage } from "../../components/Spinner";

const BASE_CLASS = "dia-DashboardLayout";

export default function DashboardLayout() {
  const { userData, isLoading } = useUserContext();
  const [showCreateDashboardModal, setShowCreateDashboardModal] =
    useState(false);

  const userHasDashboard = userData && userData.dashboards.length > 0;

  useEffect(() => {
    if (!userHasDashboard) {
      setShowCreateDashboardModal(true);
    }
  }, [userData, userHasDashboard]);

  if (isLoading) {
    return <LoadingPage />;
  } else {
    return (
      <DashboardProvider>
        {userHasDashboard ? (
          <div className={BASE_CLASS}>
            <Navbar />
            <div className={`${BASE_CLASS}-content`}>
              <Outlet />
            </div>
            <ChatBot />
          </div>
        ) : (
          <NewCreateDashboardModal
            isNew={true}
            show={showCreateDashboardModal}
            onClose={() => setShowCreateDashboardModal(false)}
          />
        )}
      </DashboardProvider>
    );
  }
}
