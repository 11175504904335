import { FC, ReactNode, useEffect } from "react";
import "./index.scss";
import Button from "../Button";

interface ModalProps {
  show: boolean;
  onClose: () => void;
  children?: ReactNode;
  onPrimaryClick?: () => void;
  onSecondaryClick: () => void;
  primaryText: string;
  secondaryText: string;
  title: string;
  subtitle?: string;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  tertiaryText?: any;
  onTertiaryClick?: () => void;
  withForm?: boolean;
  showHeader?: boolean;
}

const BASE_CLASS = "dia-Modal";

const Modal: FC<ModalProps> = ({
  show,
  onClose,
  children,
  showHeader = true,
  onPrimaryClick = () => {},
  onSecondaryClick,
  primaryText,
  secondaryText,
  title,
  subtitle,
  onSubmit,
  tertiaryText,
  onTertiaryClick = () => {},
  withForm = true,
}) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  if (!show) {
    return null;
  }

  return (
    <div className={`${BASE_CLASS}-overlay`} onClick={onClose}>
      {withForm ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            console.log("submit");
            onSubmit && onSubmit(e);
          }}
          className={`${BASE_CLASS}-content`}
          onClick={(e) => e.stopPropagation()}
        >
          {showHeader && (
            <div className={`${BASE_CLASS}-header`}>
              <h2 className={`${BASE_CLASS}-header-title`}>{title}</h2>
              {subtitle && (
                <p className={`${BASE_CLASS}-header-subtitle`}>{subtitle}</p>
              )}
            </div>
          )}
          <div className={`${BASE_CLASS}-children`}>{children}</div>
          <div className={`${BASE_CLASS}-footer`}>
            {tertiaryText ? (
              <Button type="button" variant="danger" onClick={onTertiaryClick}>
                {tertiaryText}
              </Button>
            ) : (
              <span />
            )}
            <div className={`${BASE_CLASS}-footer-actions`}>
              <Button type="submit" onClick={onPrimaryClick}>
                {primaryText}
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={onSecondaryClick}
              >
                {secondaryText}
              </Button>
            </div>
          </div>
        </form>
      ) : (
        <div
          className={`${BASE_CLASS}-content`}
          onClick={(e) => e.stopPropagation()}
        >
          {showHeader && (
            <div className={`${BASE_CLASS}-header`}>
              <h2 className={`${BASE_CLASS}-header-title`}>{title}</h2>
              {subtitle && (
                <p className={`${BASE_CLASS}-header-subtitle`}>{subtitle}</p>
              )}
            </div>
          )}
          <div className={`${BASE_CLASS}-children`}>{children}</div>
          <div className={`${BASE_CLASS}-footer`}>
            {tertiaryText ? (
              <Button type="button" variant="danger" onClick={onTertiaryClick}>
                {tertiaryText}
              </Button>
            ) : (
              <span />
            )}
            <div className={`${BASE_CLASS}-footer-actions`}>
              <Button type="button" onClick={onPrimaryClick}>
                {primaryText}
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={onSecondaryClick}
              >
                {secondaryText}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
