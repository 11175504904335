import { Customer } from "../../types/Customer.model";
import { Dashboard } from "../../types/Dashboard.model";

export class DashboardEntity {
  public id: string;
  public name: string;
  public calendars: any[];
  public companies: any[];
  public customers: Customer[];
  public notes: any[];
  public todos: any[];

  constructor(dashboard: Dashboard) {
    this.id = dashboard.id;
    this.name = dashboard.name;
    this.calendars = dashboard.calendars;
    this.companies = dashboard.companies;
    this.customers = dashboard.customers;
    this.notes = dashboard.notes;
    this.todos = dashboard.todos;
  }
}
