import { CustomerDetailData } from "../../../types/Customer.model";
import customerApi from "../../api/customerApi";

const customerRepository = {
  createCustomer: async ({
    dashboardId,
    customerData,
  }: {
    dashboardId: string;
    customerData: Partial<CustomerDetailData>;
  }) => {
    return customerApi.createCustomer({ dashboardId, customerData });
  },
  deleteCustomer: async ({
    dashboardId,
    customerId,
  }: {
    dashboardId: string;

    customerId: string;
  }) => {
    return customerApi.deleteCustomer({ dashboardId, customerId });
  },
  updateCustomer: async ({
    customerData,
  }: {
    customerData: Partial<CustomerDetailData>;
  }) => {
    return customerApi.updateCustomer({ customerData });
  },
};

export default customerRepository;
